import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HabitsStatus, Level } from "../types";
import { ReactComponent as Level1Icon } from '../Images/plan_level_1.svg'
import { ReactComponent as Level2Icon } from '../Images/plan_level_2.svg'
import { ReactComponent as Level3Icon } from '../Images/plan_level_3.svg'
import { ReactComponent as Level4Icon } from '../Images/plan_level_4.svg'


export const SectionLevels: React.FunctionComponent<{ partecipantsLevel: Record<Level, number>, joinedUsers: number }> = ({ partecipantsLevel, joinedUsers }) => {
    const { t, i18n } = useTranslation();
    return (
        <Card sx={{ borderRadius: 4 }}>
            <CardContent>
                <>
                    <Typography variant="h5" gutterBottom>{t("sustainability_level")}</Typography>
                    <Box mb={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Chip label={t("name")} color="primary" size="small"></Chip>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign:'center'}}>
                                <Chip label={t("participants")} color="primary" size="small"></Chip>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign:'center'}}>
                                <Chip label="%" color="primary" size="small"></Chip>
                            </Grid>
                        </Grid>
                    </Box>
                    {Object.keys(partecipantsLevel).map((levelNumber: string) => {
                        return (
                            <Box my={0.5} key={"level_"+levelNumber}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Box display="flex" alignItems="center">
                                            <Box mr={1} sx={{verticalAlign:'middle'}}>
                                                {levelNumber == "1" ?
                                                    <Level1Icon style={{ width: 16, height:16 }} /> : levelNumber == "2" ?
                                                        <Level2Icon style={{ width: 16, height:16 }} /> : levelNumber == "3" ?
                                                            <Level3Icon style={{ width: 16, height:16 }}/> : <Level4Icon style={{ width: 16, height:16 }}/>
                                                }
                                            </Box>
                                            <Typography variant="body1">{t("level_" + levelNumber)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} sx={{textAlign:'center'}}>
                                        {(partecipantsLevel[levelNumber as unknown as Level]).toString()}
                                    </Grid>
                                    <Grid item xs={3} sx={{textAlign:'center'}}>
                                        {((partecipantsLevel[levelNumber as unknown as Level]) / joinedUsers * 100).toFixed(1)} %
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    })}
                </>
            </CardContent>
        </Card>
    )

}