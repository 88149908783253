import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, getFirestore, Timestamp } from "firebase/firestore"
import { Box, Container, Grid, Stack, CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { Header } from "./Components/Header";
import { Challenge } from "./types";
import { ChallengeHeader } from "./Components/ChallengeHeader";
import { InitialStatus } from "./Components/InitialStatus";
import { SectionActivities } from "./Components/SectionActivities";
import { SectionLevels } from "./Components/SectionLevels";
import { SectionHabitsCurrent } from "./Components/SectionHabitsCurrent";
import { SectionSummary } from "./Components/SectionSummary";
import theme from "./theme";
import { SectionHabitsDone } from "./Components/SectionHabitsDone";
import { customTheme } from "./themeCustom";

export const getPrimaryColor = (challenge: Challenge) => {
    return challenge != null && challenge.color != null ? "#"+challenge.color.slice(4,10) : theme.palette.primary.main
}


export const ChallengeDashboard: React.FunctionComponent = () => {
    let { id } = useParams<"id">();
    const [challenge, setChallenge] = React.useState<Challenge | null>(null)
    const [loaded, setLoaded] = React.useState(false)
    const [internalTheme, setInternalTheme] = React.useState(theme)

    useEffect(() => {
        const db = getFirestore();
        async function getChallenge() {
            if (id) {
                var ref = doc(db, "challenges", id)
                const docSnap = await getDoc(ref)
                if (docSnap.exists()) {
                    var data = docSnap.data() as Challenge
                    setChallenge(data)
                    setLoaded(true)
                    setInternalTheme(customTheme(getPrimaryColor(data)))
                }
                setLoaded(true)
            }
            setLoaded(true)
        }
        if (!loaded) {
            getChallenge()
        }


    }, [challenge])

    if (loaded == false) {
        return (
            <Box sx={{ width: '100vw', height: '100vh' }} display="flex" alignItems="center" justifyContent={"center"}>
                <CircularProgress />
            </Box>
        )
    }

    if (loaded == true && challenge == null) {
        return (
            <Box sx={{ width: '100vw', height: '100vh' }} display="flex" alignItems="center" justifyContent={"center"}>
                Not found
            </Box>
        )
    }

   

    

    return (
        <Box>
            <Header />
            {challenge && id && <>
                <ThemeProvider theme={internalTheme}>
                    <ChallengeHeader challenge={challenge} />
                    <Box mt={2} mb={4}>
                        <Container maxWidth="xl">
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InitialStatus averageCarbonFootprint={challenge.dashboard_data.average_carbon_footprint} />
                                </Grid>
                                <Grid item lg={9} md={8} sm={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <SectionActivities joinedUsers={challenge.signed_up_users} graphCustomDimension={challenge.graph_custom_dimension} challenge={challenge} challengeId={id} />
                                            {challenge.dashboard_data.current_habits_ids != undefined && challenge.dashboard_data.current_habits_ids.length > 0 && 
                                            <Box mt={3}>
                                                <SectionHabitsCurrent currentHabitsIds={challenge.dashboard_data.current_habits_ids} />
                                            </Box>
                                            }
                                            <Box mt={3}>
                                                <SectionLevels joinedUsers={challenge.signed_up_users} partecipantsLevel={challenge.dashboard_data.participants_level} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <SectionSummary challenge={challenge} />
                                            <Box mt={3}>
                                                {challenge.habits_status != undefined && 
                                                    <SectionHabitsDone habitsStatus={challenge.habits_status} />
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </ThemeProvider>
            </>
            }
        </Box>
    )
}


