import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material"
import { collection, doc, DocumentSnapshot, DocumentData, getDoc, getFirestore } from "firebase/firestore"
import React from "react"
import { useTranslation } from "react-i18next"
import SimpleBar from "simplebar-react"
import { Habit, Language } from "../types"
import { BUCKETS, Categories } from "./Utils"

import 'simplebar/dist/simplebar.min.css';

interface HabitsCurrent { id: string, count: Number, habit: Habit }

const SectionHabitRow: React.FunctionComponent<{ habitObj: HabitsCurrent }> = ({ habitObj }) => {
    //@ts-ignore
    const BucketObjImage: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = BUCKETS[habitObj.habit.bucket]

    const categoryObj = Categories[habitObj.habit.category as "home" | "transportation" | "food" | "lifestyle"]

    const { i18n } = useTranslation()

    return (
        <>
            <Grid item xs={9}>
                <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    <Box display="inline-block">
                        <Box mr={1} sx={{ width: 20, height: 20, border: '1px solid ' + categoryObj.color, borderRadius: 60, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                            <BucketObjImage style={{ width: 12, height: 12, fill: categoryObj.color }} />
                        </Box>
                    </Box>
                    {habitObj.habit && habitObj.habit.title[i18n.language as Language]}
                </Box>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                {habitObj.count}
            </Grid>
        </>
    )

}

export const SectionHabitsCurrent: React.FunctionComponent<{ currentHabitsIds: { id: string, count: number }[] }> = ({ currentHabitsIds }) => {

    const [currentHabits, setCurrentHabits] = React.useState<HabitsCurrent[]>([])
    const [loaded, setLoaded] = React.useState(false)

    const { t, i18n } = useTranslation();

    React.useEffect(() => {

        async function getHabits() {
            const db = getFirestore()
            const batches: Promise<DocumentSnapshot<DocumentData>>[] = []
            currentHabitsIds.forEach((item) => {
                var ref = doc(db, "habits", item.id)
                batches.push(getDoc(ref))
            })



            const data = await Promise.all(batches)

            const dataDone: HabitsCurrent[] = data.map(element => {
                var data = element.data() as Habit

                var getCountIndex = currentHabitsIds.findIndex((item) => item.id == element.id)
                return {
                    habit: data,
                    id: element.id,
                    count: currentHabitsIds[getCountIndex].count
                }
            });


            setCurrentHabits(dataDone)
            setLoaded(true)
        }

        if (!loaded) {
            getHabits()
        }

    }, [currentHabitsIds])

    return (
        <Card sx={{ borderRadius: 4 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>{t("habits_progress")}</Typography>
                <Box mb={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Chip label={t("name")} color="primary" size="small"></Chip>
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'center' }}>
                            <Chip label={t("participants")} color="primary" size="small"></Chip>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ maxHeight: 200 }}>
                    <SimpleBar style={{ maxHeight: 200 }}>
                        <Grid container columnSpacing={1} rowSpacing={0.5}>
                            {currentHabits.length > 0 && currentHabits.map((habitObj) => {
                                if (habitObj.habit != null)
                                    return <React.Fragment key={'habitprogress' + habitObj.id}><SectionHabitRow habitObj={habitObj} /></React.Fragment>

                                return <React.Fragment key={'habitprogress' + habitObj.id}></React.Fragment>

                            })}
                        </Grid>
                    </SimpleBar>
                </Box>

            </CardContent>
        </Card>
    )

}

