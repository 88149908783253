import { Box, Chip, CircularProgress, Typography } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import { doc, DocumentData, getDoc, getDocs, getFirestore } from "firebase/firestore"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Challenge, Language } from "../types";

interface CustomDimensions {
    [key: string]: {
        co2_saving: number,
        completed_habits: number,
        signed_up_users: number,
        started_habits: number
    } 
}

export const SectionActivities: React.FunctionComponent<{challenge: Challenge, challengeId: string, graphCustomDimension: string, joinedUsers: number}> = ({ challenge, challengeId, graphCustomDimension, joinedUsers }) => {

    const [loaded, setLoaded] = React.useState(false)
    const [activities, setActivities] = React.useState<null | CustomDimensions>(null)

    const { t, i18n } = useTranslation();

    const getTranslatedName = (string: string) => {
        const answers = challenge.form.flow.find((item) => item.id == graphCustomDimension)
        if (answers){
            const translatedString = answers.answers.find((item) => item.id == string)
            if (translatedString){
                return translatedString.text[i18n.language as Language]
            }
            return string
        }else{
            return string
        }
    }

    useEffect(() => {
        const db = getFirestore();
        async function getChallenge() {
            if (challengeId) {
                var ref = doc(db, "challenges", challengeId, "custom_dimension_status", graphCustomDimension)
                const docSnap = await getDoc(ref)
                if (docSnap.exists()) {
                    var data = docSnap.data() as CustomDimensions
                    setActivities(data)
                    setLoaded(true)
                }
                setLoaded(true)
            }
            setLoaded(true)
        }
        if (!loaded){
            getChallenge()            
        }


        



    }, [activities])

    return (
    <Card  sx={{borderRadius:4}}>       
            {activities ?
                 <CardContent>
                     <Grid spacing={2} container>
                     {Object.keys(activities).map((activity, key) => {
                         return <Grid key={activity} item xs={6}>
                             <Box><Chip color="primary" size="small" label={getTranslatedName(activity)} /></Box>
                             <Grid container spacing={2} wrap="nowrap">
                                 {activities[activity].signed_up_users != undefined && 
                                <Grid item xs>
                                    <Typography variant="h6">{activities[activity].signed_up_users}</Typography>
                                    <Typography variant="body2" sx={{fontSize:10}}>{t("participants")}</Typography>
                                </Grid>
                                }
                                 {activities[activity].signed_up_users != undefined && joinedUsers != undefined && 
                                <Grid item xs>
                                    <Typography variant="h6">{Math.round((activities[activity].signed_up_users/joinedUsers)*100)} %</Typography>
                                    <Typography variant="body2" sx={{fontSize:10}}>{t("total")}</Typography>
                                </Grid>
                                }
                                 {activities[activity].co2_saving != undefined &&   
                                <Grid item xs>
                                    <Typography variant="h6">{activities[activity].co2_saving.toFixed(1)}<small style={{fontSize:9}}> ton</small> </Typography>
                                    <Typography variant="body2" sx={{fontSize:10}}>{t("co2_consumption")}</Typography>
                                </Grid>
                                }
                            </Grid>
                         </Grid>
                     })}
                     </Grid>
                </CardContent>
            : <CardContent>
                <Box sx={{ width: '100vw', height: 200 }} display="flex" alignItems="center" justifyContent={"center"}>
                    <CircularProgress size={30} sx={{ opacity: 0.3 }} />
                </Box>
                </CardContent>}        
            </Card>
    )
}