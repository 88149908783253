import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { Categories } from "./Utils";

interface Lines {
    color: string,
    r: number,
    lenght: number,
    max: number
}

const colors = [Categories.lifestyle.color, Categories.food.color, Categories.transportation.color, Categories.home.color]
const colorsDark = [Categories.lifestyle.comparisonColor, Categories.food.comparisonColor, Categories.transportation.comparisonColor, Categories.home.comparisonColor]

var spacing = 14
var largestRay = 80

const ray = [largestRay, largestRay-(spacing*1), largestRay-(spacing*2), largestRay-(spacing*3), largestRay-(spacing*4)]

const getFirstGraphs = (series: number[], useColorsDark :boolean) => {
    const graphs: Lines[] = series.map((seriesSingle, key) => {
        return {
            color: useColorsDark ? colorsDark[key] : colors[key],
            r: ray[key],
            lenght: 0,
            max: 7 * ray[key]
        }
    })
    return graphs
}

const getGraphs = (series: number[], useColorsDark :boolean) => {
    const graphs: Lines[] = series.map((seriesSingle, key) => {
        return {
            color: useColorsDark ? colorsDark[key] : colors[key],
            r: ray[key],
            lenght: (seriesSingle / 100) * 2 * Math.PI * ray[key],
            max: 7 * ray[key]
        }
    })
    return graphs
}

export const RadialChart: React.FunctionComponent<{ series: number[] }> = ({ series }) => {

    const [graphs, setGraphs] = React.useState(getFirstGraphs(series,false))

    useEffect(() => {

        setGraphs(getGraphs(series,false))

    }, [series])

    return (
        <Box>
            <svg viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                {graphs.map((graph, key) => {
                    return <React.Fragment key={'bhcircle_' + key}>
                        <circle cx="90"                            
                            cy="90"
                            r={graph.r}
                            fill="none"
                            strokeWidth={10}
                            stroke={'#F5F5F5'}></circle>
                        <circle
                            
                            className="radial-chart-progress"
                            cx="90"
                            cy="90"
                            r={graph.r}
                            fill="none"
                            stroke={graph.color}
                            strokeWidth={10}
                            strokeDasharray={`${graph.lenght},${graph.max}`}
                            strokeLinecap={'round'}
                        ></circle></React.Fragment>
                })}
            </svg>
        </Box>
    )
}




export const RadialChartComparison: React.FunctionComponent<{ series: number[] }> = ({ series }) => {

    const [graphs, setGraphs] = React.useState(getFirstGraphs(series,true))

    useEffect(() => {

        setGraphs(getGraphs(series,true))

    }, [series])

    return (
        <Box>
            <svg viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                {graphs.map((graph, key) => {
                    return <circle
                        key={'comparison_circle_' + key}
                        className="radial-chart-progress"
                        cx="90"
                        cy="90"
                        r={graph.r}
                        fill="none"
                        stroke={graph.color}
                        strokeWidth={3}
                        strokeDasharray={`${graph.lenght},${graph.max}`}
                        strokeLinecap={'round'}
                    ></circle>
                })}
            </svg>
        </Box>
    )
}
