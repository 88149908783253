import { Box, Button, Container, Menu, MenuItem } from "@mui/material"
import { ReactComponent as Logo } from '../Images/logo_theplanetapp.svg'
import * as React from 'react';
import { useTranslation } from "react-i18next";

export const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const itemClick = (language: string) => {
        i18n.changeLanguage(language)
        setAnchorEl(null);
    }
    const {t, i18n} = useTranslation()
    return <>
        <Container maxWidth={false}>
            <Box py={2} sx={{ borderBottom: '1px solid #DBDBDB' }} display="flex" justifyContent="space-between">
                <Logo />
                <Box>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <strong>{t(i18n.language+"_name")}</strong>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => itemClick('en')}>{t("en_name")}</MenuItem>
                        <MenuItem onClick={() => itemClick('es')}>{t("es_name")}</MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Container>
    </>
}