import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { getFirestore, DocumentSnapshot, DocumentData, doc, getDoc } from "firebase/firestore";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { Habit, HabitsStatus, Language } from "../types";
import { BUCKETS, Categories } from "./Utils";

interface SectionHabitsDoneProps {
    habitsStatus: Record<string, HabitsStatus>
}

interface HabitsDone { id: string, co2_saving: number, times_completed: number, times_started: number, habit: Habit }

const SectionHabitRow: React.FunctionComponent<{ habitObj: HabitsDone }> = ({ habitObj }) => {
    //@ts-ignore
    const BucketObjImage: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = BUCKETS[habitObj.habit.bucket]

    const categoryObj = Categories[habitObj.habit.category as "home" | "transportation" | "food" | "lifestyle"]

    const { i18n, t } = useTranslation()

    return (
        <>
        <Grid item xs={6}>
            <Box  sx={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>
                <Box display="inline-block">
                    <Box mr={1} sx={{ width: 20, height: 20, border: '1px solid '+categoryObj.color, borderRadius: 60, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                        <BucketObjImage style={{ width: 12, height: 12, fill:categoryObj.color }} />
                    </Box>
                </Box>
                {habitObj.habit && habitObj.habit.title[i18n.language as Language]}
            </Box>
        </Grid>
        <Grid item xs={3} sx={{textAlign:'center'}}>
            {habitObj.times_completed}
        </Grid>
        <Grid item xs={3} sx={{textAlign:'center'}}>
            {habitObj.co2_saving.toFixed(2)} <small style={{fontSize:8}}>{t("ton_year")}</small>
        </Grid>
        </>
    )

}

export const SectionHabitsDone: React.FunctionComponent<SectionHabitsDoneProps> = ({habitsStatus}) => {
    const { t, i18n } = useTranslation();
    const [doneHabits, setDoneHabits] = React.useState<HabitsDone[]>([])
    const [loaded, setLoaded] = React.useState(false)

    React.useEffect(() => {

        const currentHabitsStatusids = Object.keys(habitsStatus)

        async function getHabits() {
            const db = getFirestore()
            const batches: Promise<DocumentSnapshot<DocumentData>>[] = []
            currentHabitsStatusids.forEach((item) => {
                const ref = doc(db, "habits", item)
                batches.push(getDoc(ref))
            })

            const data = await Promise.all(batches)

            const dataDone: HabitsDone[] = data.map(element => {
                const data = element.data() as Habit

                const habitsStatusObj = habitsStatus[element.id]
                return {
                    habit: data,
                    id: element.id,
                    co2_saving: habitsStatusObj.co2_saving,
                    times_completed: habitsStatusObj.times_completed,
                    times_started: habitsStatusObj.times_started
                }
            });

            dataDone.sort((a,b) => b.co2_saving - a.co2_saving)


            setDoneHabits(dataDone)
            setLoaded(true)
        }

        if (!loaded) {
            getHabits()
        }

    }, [habitsStatus])

    return (
        <Card sx={{borderRadius:4}}>
            <CardContent>
                 <Typography variant="h5" gutterBottom>{t("habits_done_impact")}</Typography>
                <Box mb={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Chip label={t("name")} color="primary" size="small"></Chip>
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'center' }}>
                            <Chip label={t("participants")} color="primary" size="small"></Chip>
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'center' }}>
                            <Chip label={t("co2_consumption")} color="primary" size="small"></Chip>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ maxHeight: 300 }}>
                    <SimpleBar style={{ maxHeight: 300 }}>
                        <Grid container columnSpacing={1} rowSpacing={0.5}>
                            {doneHabits.length > 0 && doneHabits.map((habitObj) => {
                                if (habitObj.habit != null  && habitObj.times_completed > 0)
                                    return <React.Fragment key={'habitprogress' + habitObj.id}><SectionHabitRow habitObj={habitObj} /></React.Fragment>

                                return <React.Fragment key={'habitprogress' + habitObj.id}></React.Fragment>

                            })}
                        </Grid>
                    </SimpleBar>
                </Box>
            </CardContent>
        </Card>
    )
}