import { Box, Card, CardContent, getContrastRatio, Grid, styled, Typography, useTheme } from "@mui/material"
import { Challenge } from "../types"
import { ReactComponent as LogoCo2 } from "../Images/icon_co2_cloud.svg"
import { getPrimaryColor } from "../ChallengeDashboard"
import { useTranslation } from "react-i18next"

interface SectionSummaryProps {
    challenge: Challenge
}

export const SectionSummary: React.FunctionComponent<SectionSummaryProps> = ({ challenge }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme()
    const contrastThreshold = 3

    const isDark = getContrastRatio(theme.palette.background.default, theme.palette.primary.main) >= contrastThreshold ? true : false;
    const elementsColor = isDark ? theme.palette.primary.main : theme.palette.primary.contrastText
    return (
        <Card sx={{ borderRadius: 4, border: "3px solid", borderColor: isDark ? 'primary.main' : 'transparent', backgroundColor: isDark ? 'transparent' : 'primary.main' }}>
            <CardContent>
                <Typography variant="h5" color={elementsColor} gutterBottom>
                    <Box display="flex" alignItems="center">
                        <Box sx={{
                            borderColor: elementsColor,
                            'svg path': { fill: elementsColor }
                        }}><LogoCo2 /></Box> <Box pl={1}>{t("our_current_reduction")}</Box></Box></Typography>
                {challenge.co2_reduction_current && challenge.co2_reduction_goal ?
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography variant="h5">{challenge.co2_reduction_current.toFixed(2)} <small style={{ fontWeight: 'normal' }}>{t("ton_year")}</small></Typography></Grid>
                        <Grid item xs><Typography variant="h5">{(challenge.co2_reduction_current / challenge.co2_reduction_goal * 100).toFixed(2)} % <small style={{ fontWeight: 'normal' }}>{t("of_goal")}</small></Typography></Grid>
                    </Grid>
                    : <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography variant="h5">0 <small style={{ fontWeight: 'normal' }}>{t("ton_year")}</small></Typography></Grid>
                        <Grid item xs><Typography variant="h5">0 % <small style={{ fontWeight: 'normal' }}>{t("of_goal")}</small></Typography></Grid>
                    </Grid>
                }
            </CardContent>
        </Card>
    )
}