// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCBnqUmo1BoTWxU4T7joaUX_DUfFQGbPH0",
  authDomain: "theplanetapp-prod.firebaseapp.com",
  databaseURL: "https://theplanetapp-prod.firebaseio.com",
  projectId: "theplanetapp-prod",
  storageBucket: "theplanetapp-prod.appspot.com",
  messagingSenderId: "126411661379",
  appId: "1:126411661379:web:12c5a4e05a519dfe02b4f6",
  measurementId: "G-XFW87Q64V3"
};

//STAGING

/*export const firebaseConfig = {
  apiKey: "AIzaSyBdPtwUuWDQfLOlmye6pWb5N1RV-UNCLAo",
  authDomain: "theplanetapp-staging.firebaseapp.com",
  databaseURL: "https://theplanetapp-staging.firebaseio.com",
  projectId: "theplanetapp-staging",
  storageBucket: "theplanetapp-staging.appspot.com",
  messagingSenderId: "63348641839",
  appId: "1:63348641839:web:8108c60136edd04faa36da",
  measurementId: "G-FMTRRWMMGW"
};*/


