import { AverageCarboonFootprint } from "../types";
import { ReactComponent as FemaleB } from '../Images/femaleB.svg'
import { ReactComponent as MaleA } from '../Images/maleA.svg'
import { ReactComponent as MaleC } from '../Images/maleC.svg'
import { ReactComponent as Greta } from '../Images/greta.svg'
import { ReactComponent as BusCityIcon } from '../Images/bike_icon.svg'
import { ReactComponent as CarIcon } from '../Images/car_icon.svg'
import { ReactComponent as EnergyIcon } from '../Images/energy_icon.svg'
import { ReactComponent as ForkIcon } from '../Images/fork_icon.svg'
import { ReactComponent as HeatingIcon } from '../Images/heating_icon.svg'
import { ReactComponent as BikeIcon } from '../Images/bike_icon.svg'
import { ReactComponent as HomeIcon } from '../Images/home_icon.svg'
import { ReactComponent as PhoneIcon } from '../Images/phone_icon.svg'
import { ReactComponent as PlaneIcon } from '../Images/plane_icon.svg'
import { ReactComponent as ShirtIcon } from '../Images/shirt_icon.svg'
import { ReactComponent as ChefIcon } from '../Images/chef_icon.svg'

import { ReactComponentElement, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";




export const Categories = {
    home: {
        color: '#EABB4C',
        colorLight: '#FFF4DB',
        comparisonColor: '#D2A844',
        icon: 'icon_home.png',
        image: 'catimage_home.png'
    },
    transportation: {
        color: '#C055D5',
        colorLight: '#FBE9FF',
        comparisonColor: '#AC4CBF',
        icon: 'catimage_transportation.png',
        image: 'icon_transport.png'
    },
    food: {
        color: '#55D590',
        colorLight: '#D9FFEA',
        comparisonColor: '#4CBF81',
        icon: 'icon_food.png',
        image: 'catimage_food.png'
    },
    lifestyle: {
        color: '#EC4848',
        colorLight: '#FFD1D1',
        comparisonColor: '#D44040',
        icon: 'icon_lifestyle.png',
        image: 'catimage_lifestyle.png'
    }
}

export const BUCKETS = {
    "bus_city": BusCityIcon,
    "car": CarIcon,
    "motorbike": CarIcon,
    "car_spending": CarIcon,
    "electricity": EnergyIcon,
    "groceries": ForkIcon,
    "heating": HeatingIcon,
    "metro": BikeIcon,
    "restaurants": ChefIcon,
    "mortgage": HomeIcon,
    "telephone": PhoneIcon,
    "flights_short": PlaneIcon,
    "flights_medium": PlaneIcon,
    "flights_long": PlaneIcon,
    "clothing": ShirtIcon,
    "smartphones": PhoneIcon
}


export const EMISSION_GOALS: AverageCarboonFootprint = {
    home: 0.3,
    transportation: 0.4,
    food: 0.9,
    lifestyle: 0.4
};
export const EMISSIONS_AVERAGE = 10.0;
/*var emissionsGoalsTotal =
    Object.keys(EMISSION_GOALS).map((e) => EMISSION_GOALS[e]).reduce((a, b) => a + b);*/

export interface SampleUser {
    id: string,
    values: AverageCarboonFootprint,
    valuesPerc: AverageCarboonFootprint,
    image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const USERS: SampleUser[] = [
    {
        id: "worldAvg",
        values: { home: 0.8, transportation: 1.6, food: 1.4, lifestyle: 0.7 },
        valuesPerc: {
            home: lerpEmissions(0.8, EMISSION_GOALS["home"]) * 100,
            transportation: lerpEmissions(1.6, EMISSION_GOALS["transportation"]) * 100,
            food: lerpEmissions(1.4, EMISSION_GOALS["food"]) * 100,
            lifestyle: lerpEmissions(0.7, EMISSION_GOALS["lifestyle"]) * 100
        },
        image: MaleA
    },
    {
        id: "spainAvg",
        values: { home: 0.5, transportation: 4.1, food: 2.1, lifestyle: 0.9 },
        valuesPerc: {
            home: lerpEmissions(0.5, EMISSION_GOALS["home"]) * 100,
            transportation: lerpEmissions(4.1, EMISSION_GOALS["transportation"]) * 100,
            food: lerpEmissions(2.1, EMISSION_GOALS["food"]) * 100,
            lifestyle: lerpEmissions(0.9, EMISSION_GOALS["lifestyle"]) * 100
        },
        image: FemaleB
    },
    {
        id: "thePlanetAppAvg",
        values: { home: 0.5, transportation: 2.4, food: 1.9, lifestyle: 1.1 },
        valuesPerc: {
            home: lerpEmissions(0.5, EMISSION_GOALS["home"]) * 100,
            transportation: lerpEmissions(2.4, EMISSION_GOALS["transportation"]) * 100,
            food: lerpEmissions(1.9, EMISSION_GOALS["food"]) * 100,
            lifestyle: lerpEmissions(1.1, EMISSION_GOALS["lifestyle"]) * 100
        },
        image: MaleC
    },
    {
        id: "greta",
        values: EMISSION_GOALS,
        valuesPerc: {
            home: lerpEmissions(EMISSION_GOALS["home"], EMISSION_GOALS["home"]) * 100,
            transportation: lerpEmissions(EMISSION_GOALS["transportation"], EMISSION_GOALS["transportation"]) * 100,
            food: lerpEmissions(EMISSION_GOALS["food"], EMISSION_GOALS["food"]) * 100,
            lifestyle: lerpEmissions(EMISSION_GOALS["lifestyle"], EMISSION_GOALS["lifestyle"]) * 100
        },
        image: Greta
    }
];

export function lerpEmissions(emissions: number, goal: number) {
    return Math.min(1, 1 - 1 / Math.pow((emissions + 1), 0.75));
  }


