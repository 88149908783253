import React, { Suspense } from 'react';
import logo from './logo.svg';
import { Link, Routes, Route } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { ChallengeDashboard } from './ChallengeDashboard';
import { firebaseConfig } from './firebase'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import './i18n';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from './Images/logo_theplanetapp.svg'

export const firebaseApp = initializeApp(firebaseConfig);

function Home() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Container sx={{ textAlign: 'center' }}>
        <Box pt={4}>
          <Logo style={{width:290, height:70}}/>
          <Box mt={3}>
          <Typography variant="h4">{t('welcome')}</Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
}



function App() {
  // Initialize Firebase
  return (

    <Suspense fallback="loading">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<ChallengeDashboard />} />
      </Routes>
    </Suspense>

  );
}

export default App;
