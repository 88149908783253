import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from "@mui/material"
import { collection, doc, getDocs, getFirestore, query, where } from "firebase/firestore";
import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Challenge } from "../types";
import { firebaseApp } from "../App";
import { RadialChart, RadialChartComparison } from "./ChallengeChart";
import { Categories, EMISSION_GOALS, lerpEmissions, USERS, SampleUser } from "./Utils";
import { useTranslation } from "react-i18next";


type AverageCarboonFootprint = {
    food: number,
    transportation: number,
    lifestyle: number,
    home: number
}

const InitialStatusButton = (sampleUser: SampleUser, activeSampleUser: SampleUser | null, onClick: (sampleUser: SampleUser) => void) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    return <>
        <Button sx={{ minWidth: 40, padding: "5px 5px" }} fullWidth variant="contained" color={activeSampleUser != null && sampleUser.id == activeSampleUser.id ? "primary" : "inherit"} onClick={() => onClick(sampleUser)}>
            <sampleUser.image style={{ width: 20, fill: activeSampleUser != null && sampleUser.id == activeSampleUser.id ? theme.palette.primary.contrastText : "inherit" }} />
        </Button>
        <Box mt={1} textAlign="center">
            <Typography variant="body2" sx={{ fontSize: 9, lineHeight: 1 }}>{t(sampleUser.id)}</Typography>
        </Box>
    </>
}

export const InitialStatus: React.FunctionComponent<{ averageCarbonFootprint: AverageCarboonFootprint }> = ({ averageCarbonFootprint }) => {

    const [averageCarbonFootprintPerc, setAverageCarbonFootprintPerc] = React.useState<AverageCarboonFootprint>({ food: 0, transportation: 0, lifestyle: 0, home: 0 })
    const [comparison, setComparison] = React.useState<SampleUser | null>(null)
    const { t, i18n } = useTranslation();

    React.useEffect(() => {

        const percentageObject: AverageCarboonFootprint = { food: 0, transportation: 0, lifestyle: 0, home: 0 }
        Object.keys(averageCarbonFootprint).map((item) => {
            //@ts-ignore
            percentageObject[item] = lerpEmissions(averageCarbonFootprint[item], EMISSION_GOALS[item]) * 100
        }
        )
        setAverageCarbonFootprintPerc(percentageObject)




    }, [averageCarbonFootprint])



    return <Card sx={{ borderRadius: 4 }}>
        <CardContent>
            <Typography variant="h6">{t("hereStart")}</Typography>
            <Typography variant="body2">{t("hereStartDesc")}</Typography>
            <Box sx={{ position: 'relative', width: '60%', margin: '0 auto' }} py={1}>
                <RadialChart series={[averageCarbonFootprintPerc.lifestyle, averageCarbonFootprintPerc.food, averageCarbonFootprintPerc.transportation, averageCarbonFootprintPerc.home,]} />
                {comparison != null &&
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} py={1}>
                        <RadialChartComparison series={[comparison.valuesPerc.lifestyle, comparison.valuesPerc.food, comparison.valuesPerc.transportation, comparison.valuesPerc.home,]} />
                    </Box>
                }
            </Box>


            <Box py={1}>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item xs={4}>
                        <Box display="flex" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>
                            <Box sx={{ bgcolor: Categories.home.color, width: 10, height: 10, borderRadius: 30 }} mr={1}></Box>
                            <Box>{averageCarbonFootprint.home.toFixed(2)} ton CO<sub>2</sub></Box>
                        </Box>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center', fontSize: '0.8rem' }}>{t("home")}</Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        {comparison != null &&
                            <Box display="flex" justifyContent="end" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>

                                <Box>{comparison.values.home.toFixed(2)} ton CO<sub>2</sub></Box>
                                <Box sx={{ bgcolor: Categories.home.color, width: 10, height: 4, borderRadius: 30 }} ml={1}></Box>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box pb={1}>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item xs={4}>
                        <Box display="flex" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>
                            <Box sx={{ bgcolor: Categories.transportation.color, width: 10, height: 10, borderRadius: 30 }} mr={1}></Box>
                            <Box>{averageCarbonFootprint.transportation.toFixed(2)} ton CO<sub>2</sub></Box>
                        </Box>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center', fontSize: '0.8rem' }}>{t("transportation")}</Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        {comparison != null &&
                            <Box display="flex" justifyContent="end" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>

                                <Box>{comparison.values.transportation.toFixed(2)} ton CO<sub>2</sub></Box>
                                <Box sx={{ bgcolor: Categories.transportation.color, width: 10, height: 4, borderRadius: 30 }} ml={1}></Box>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box pb={1}>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item xs={4}>
                        <Box display="flex" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>
                            <Box sx={{ bgcolor: Categories.food.color, width: 10, height: 10, borderRadius: 30 }} mr={1}></Box>
                            <Box>{averageCarbonFootprint.food.toFixed(2)} ton CO<sub>2</sub></Box>
                        </Box>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center', fontSize: '0.8rem' }}>{t("food")}</Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        {comparison != null &&
                            <Box display="flex" justifyContent="end" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>

                                <Box>{comparison.values.food.toFixed(2)} ton CO<sub>2</sub></Box>
                                <Box sx={{ bgcolor: Categories.food.color, width: 10, height: 4, borderRadius: 30 }} ml={1}></Box>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box pb={1}>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item xs={4}>
                        <Box display="flex" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>
                            <Box sx={{ bgcolor: Categories.lifestyle.color, width: 10, height: 10, borderRadius: 30 }} mr={1}></Box>
                            <Box>{averageCarbonFootprint.lifestyle.toFixed(2)} ton CO<sub>2</sub></Box>
                        </Box>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center', fontSize: '0.8rem' }}>{t("lifestyle")}</Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        {comparison != null &&
                            <Box display="flex" justifyContent="end" alignItems="center" sx={{ fontSize: '0.6rem', lineHeight: 1 }}>

                                <Box>{comparison.values.lifestyle.toFixed(2)} ton CO<sub>2</sub></Box>
                                <Box sx={{ bgcolor: Categories.lifestyle.color, width: 10, height: 4, borderRadius: 30 }} ml={1}></Box>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>


            <Box mt={2}>
                <Grid container spacing={1} wrap="nowrap">
                    {
                        USERS.map((sampleUser, key) => {
                            return <Grid item xs key={"sample" + key}>
                                {InitialStatusButton(sampleUser, comparison, () => setComparison(sampleUser))}
                            </Grid>
                        })
                    }

                </Grid>
            </Box>

        </CardContent>
    </Card>
}