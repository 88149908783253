import * as React from 'react';
import { Challenge, Language } from "../types";
import { getStorage, ref } from 'firebase/storage';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { Container, Box, CardMedia, CircularProgress, Typography, Grid } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

export const ChallengeHeader: React.FunctionComponent<{ challenge: Challenge }> = ({ challenge }) => {
    const storage = getStorage();
    const [value, loading, error] = useDownloadURL(ref(storage, challenge.logo));
    const {i18n, t} = useTranslation()
    const language = i18n.language as Language

    return <Container maxWidth="xl">
        <Box mt={2}><Typography variant="h4" gutterBottom>Challenge {challenge.organization}</Typography></Box>
        <Grid container alignItems="center" columnSpacing={2}>
            <Grid item sm="auto">
                <Box sx={{ maxWidth: 230 }}>
                    {loading && <Box sx={{ opacity: 0.3, height: 180, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress size={30} sx={{ opacity: 0.3 }} />
                    </Box>}
                    {!loading && <img src={value} style={{ width: '100%' }} />}
                </Box>
            </Grid>
            <Grid item sm>
                <ReactMarkdown
                    components={{
                        h2: (props) => <Typography {...props} variant="h5" component="h2" />,
                        h3: (props) => <Typography {...props} variant="h6" component="h3" />,
                        h4: (props) => <Typography {...props} variant="h6" component="h4" />,
                        p: (props) => <Typography {...props} variant="body1" />
                    }}
                >{challenge.description[language]}</ReactMarkdown>
            </Grid>
        </Grid>
        
    </Container>
}