import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "welcome": "Welcome to The planet app dashboard",
      "hereStart": "This is where we start",
      "hereStartDesc": "Average personal carbon footprint of the challenge participants",
      "home": "Home",
      "transportation": "Transportation",
      "lifestyle": "Lifestyle",
      "food": "Food",
      "worldAvg": "World average",
      "spainAvg": "Spain average",
      "thePlanetAppAvg": "The Planet app average",
      "greta": "Greta",
      "en_name": "English",
      "es_name": "Spanish",
      "co2_consumption": "Total savings",
      "participants": "Participants",
      "total": "Total",
      "sustainability_level": "Level of sustainability",
      "level_1": "Level 1: Aware",
      "level_2": "Level 2: Committed",
      "level_3": "Level 3: Sustainable",
      "level_4": "Level 4: The Planet Hero",
      "name": "Name",
      "habits_progress": "Habits in progress",
      "our_current_reduction": "Current total reduction",
      "ton_year": "ton/year",
      "of_goal": "of goal",
      "habits_done_impact":"Habits done and total impact"
    }
  },
  es: {
    translation: {
      "welcome": "Welcome to The planet app dashboard",
      "hereStart": "De aquí partimos",
      "hereStartDesc": "Huella de carbono personal promedio de los participantes en el reto",
      "home": "Casa",
      "transportation": "Transporte",
      "lifestyle": "Estilo de vida",
      "food": "Comida",
      "worldAvg": "World average",
      "spainAvg": "Spain average",
      "thePlanetAppAvg": "The Planet app average",
      "greta": "Greta",
      "en_name": "Inglés",
      "es_name": "Español",
      "co2_consumption": "Total ahorros",
      "participants": "Participantes",
      "total": "Total",
      "sustainability_level": "Nivel de sostenibilidad",
      "level_1": "Nivel 1: Concienciado",
      "level_2": "Nivel 2: Comprometido",
      "level_3": "Nivel 3: Sostenible",
      "level_4": "Nivel 4: The Planet Hero",
      "name": "Nombre",
      "habits_progress": "Hábitos en curso",
      "our_current_reduction": "Nuestra reducción total actual",
      "ton_year": "ton/año",
      "of_goal": "del objectivo",
      "habits_done_impact":"Hábitos conseguidos y impacto total"
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    fallbackLng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;